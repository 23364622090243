// tabbar
export const tabbar: string[] = [
	'/pages/home',
	'/pages/goods/list',
	'/pages/order/cart',
	'/pages/mycenter/index',
	'/pages/community/index',
];

// 原生
export const native: string[] = [
	...tabbar,
	'/pages/community/expert',
	'/pages/community/kitchen',
	'/pages/community/question',
	'/pages/community/school',
	'/pages/goods/detail',
	'/pages/goods/anti-fake',
	'/pages/goods/search/list',
	'/pages/goods/search/index',
	'/pages/mycenter/login',
	'/pages/mycenter/collect',
	'/pages/order/list',
	'/pages/order/detail',
	'/pages/order/submit',
	'/pages/order/subcart',
	'/pages/order/express/list',
	'/pages/order/express/detail',
	'/pages/setting/index',
	'/pages/setting/agreement',
	'/pages/setting/location/edit',
	'/pages/setting/location/index',
	'/pages/agent/index',
	'/pages/agent/client',
	'/pages/agent/invite',
	'/pages/agent/earnest',
	'/pages/agent/scan/index',
	'/pages/agent/scan/freight',
	'/pages/agent/scan/tracing',
	'/pages/agent/scan/record',
	'/pages/agent/scan/detail',
	'/pages/agent/inventory/list',
	'/pages/agent/inventory/detail',
	'/pages/agent/inventory/deliver',
	'/pages/agent/order/cart',
	'/pages/agent/order/list',
	'/pages/agent/order/detail',
	'/pages/wallet/index',
	'/pages/wallet/income',
	'/pages/wallet/record',
	'/pages/wallet/transfer',
	'/pages/wallet/withdraw',
	'/pages/collect',
	'/pages/deliver/detail',
	'/pages/deliver/list',
	'/pages/box/pack',
	'/pages/box/unpack',
	'/pages/box/replace',
];

// 不需要登录
export const noLogin: string[] = ['/pages/mycenter/login', '/pages/setting/agreement'];

// 需要登录
export const needLogin: string[] = [
	'/pages/mycenter/collect',
	'/pages/order/list',
	'/pages/order/cart',
	'/pages/order/detail',
	'/pages/order/submit',
	'/pages/order/subcart',
	'/pages/order/express/list',
	'/pages/order/express/detail',
	'/pages/setting/index',
	'/pages/setting/location/edit',
	'/pages/setting/location/index',
	'/pages/agent/index',
	'/pages/agent/client',
	'/pages/agent/invite',
	'/pages/agent/earnest',
	'/pages/agent/scan/index',
	'/pages/agent/scan/freight',
	'/pages/agent/scan/tracing',
	'/pages/agent/scan/record',
	'/pages/agent/inventory/list',
	'/pages/agent/inventory/detail',
	'/pages/agent/inventory/deliver',
	'/pages/agent/order/cart',
	'/pages/agent/order/list',
	'/pages/agent/order/detail',
	'/pages/wallet/index',
	'/pages/wallet/income',
	'/pages/wallet/record',
	'/pages/wallet/transfer',
	'/pages/wallet/withdraw',
	'/pages/collect',
	'/pages/deliver/detail',
	'/pages/deliver/list',
	'/pages/box/pack',
	'/pages/box/unpack',
	'/pages/box/replace',
];
