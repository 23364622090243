import projectData from '@/stores/projectData';
import { computed, ref, onUnmounted } from 'vue';
import { onShow, onHide, onPageScroll } from '@dcloudio/uni-app';

export const useScroll = () => {
	let isAct = false,
		opacity = ref(0),
		iconColor = ref('white');

	onShow(() => (isAct = true));
	onHide(() => (isAct = false));
	onPageScroll(({ scrollTop }: { scrollTop: number }) => {
		if (isAct) {
			iconColor.value = scrollTop > 100 ? 'black' : 'white';

			if (scrollTop > 100) {
				let opt: any = (scrollTop - 100) / 100;
				opt = opt.toFixed(2);
				opt < 0 && (opt = 0);
				opt > 1 && (opt = 1);
				opacity.value = opt;
			} else opacity.value = 0;
		}
	});

	return {
		opacity,
		iconColor,
	};
};

export const useUserInfo = (update: boolean = false) => {
	const prjData = projectData(),
		userInfo = computed(() => prjData.userInfo);

	update && onShow(() => prjData.fetchUserInfo('init'));

	return {
		prjData,
		userInfo,
	};
};

export const useEmit = (event: string, func: Parameters<typeof uni.$emit>[1]) => {
	uni.$on(event, func);
	onUnmounted(() => uni.$off(event, func));
};
