export const API_BASE: any = {
	DOWNLOAD: 'https://download.szbelief.cn/',
	SIGN_KEY: 'l]Ybbrj_ZnFz4~9VB7)Gq7[0HGCY]oq{1*I]Eji_e[(~qVRYB#axu+r6IDzeE0*EvX2&BCWt~QmXZc==',
	dev: {
		wap: 'https://wap.dev.xy.uduoduo.vip',
		api: 'https://api.dev.xy.uduoduo.vip/Public/boosoov2/',
	},
	prod: {
		wap: 'https://wap.szbelief.cn',
		api: 'https://api.szbelief.cn/Public/boosoov2/',
	},
};
