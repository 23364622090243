import port from '@config/port';
import CryptoJS from 'crypto-js';
import request from '@utils/request';
import { noLogin } from '@config/routes';
import systemInfo from '@/stores/systemInfo';
import { navigateTo, getRoute, urlReg, parseUrl } from '@utils/router';

/** AES加密解密 */
export const AES = {
	secretKey: {
		key: CryptoJS.enc.Utf8.parse('6sa175asc@qq.com'),
		iv: CryptoJS.enc.Utf8.parse('6549846516589321'),
	},
	encrypt(data: string): string {
		data = String(data);
		let { key, iv } = this.secretKey;

		return CryptoJS.AES.encrypt(data, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}).toString();
	},
	decrypt(data: string): string {
		let { key, iv } = this.secretKey;
		return CryptoJS.AES.decrypt(data, key, {
			iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7,
		}).toString(CryptoJS.enc.Utf8);
	},
};

/** 获取当前打包平台 */
export const getPlatform = (): string => {
	let plat;
	// #ifdef H5
	plat = 'h5';
	// #endif
	// #ifdef MP
	plat = 'mp';
	// #endif
	// #ifdef APP-PLUS
	plat = uni.getSystemInfoSync().platform;
	// #endif
	return plat;
};

/** 深克隆 */
export const deepClone = (data: any) => {
	let cloneData: any;
	switch (Object.prototype.toString.call(data)) {
		case '[object Map]':
			cloneData = new Map();
			data.forEach((val: any, key: any) => cloneData.set(key, deepClone(val)));
			break;
		case '[object Array]':
			cloneData = [];
			data.forEach((val: any) => cloneData.push(deepClone(val)));
			break;
		case '[object Object]':
			cloneData = {};
			for (let key in data) cloneData[key] = deepClone(data[key]);
			break;
		default:
			cloneData = data;
	}
	return cloneData;
};

/** 防抖 */
export const debounce = (fun: Function, delay = 250) => {
	let timer: any;
	return function (this: any, ...args: any) {
		clearTimeout(timer);
		timer = setTimeout(() => fun.apply(this, args), delay);
	};
};

/** 节流 */
export const throttle = (
	func: (data: any[]) => void,
	delay: number,
	immediate: boolean = false
): ((data: any[]) => void) => {
	let lastCallTime = 0;
	let lastArgs: any[];
	let timeoutId: NodeJS.Timeout | null = null;

	return function (data: any[]) {
		const now = Date.now();
		lastArgs = data;

		const execute = () => {
			func(lastArgs);
			lastCallTime = now;
			timeoutId = null;
		};

		if (immediate) {
			if (!timeoutId) {
				execute();
				timeoutId = setTimeout(() => {
					execute();
				}, delay);
			}
		} else {
			if (!timeoutId || now - lastCallTime >= delay) {
				execute();
			}
			if (timeoutId) {
				clearTimeout(timeoutId);
				timeoutId = setTimeout(() => {
					execute();
				}, delay);
			}
		}
	};
};

/** 合并对象 */
export const assignObj = (obj1: any, obj2: any) => {
	const cloneObj1 = deepClone(obj1);
	for (let key in obj2) key in cloneObj1 && obj2[key] && (cloneObj1[key] = obj2[key]);
	return cloneObj1;
};

/** 检查更新 */
export const checkUpdated = () => {
	// #ifdef MP
	const updateManager = uni.getUpdateManager();
	updateManager.onUpdateReady(res => {
		uni.showModal({
			title: '更新提示',
			content: '新版本已经准备好，请重启应用',
			showCancel: false,
			success(res) {
				updateManager.applyUpdate();
			},
		});
	});
	// #endif
	// #ifdef APP-PLUS
	request.get({ port: port.VERSION_GETVERSION, showLoad: false, resErr: true }).then(info => {
		if (info.code == 0) {
			let { desc, isForce, isNew, updateUrl, content } = info;
			if (isNew != 1 && updateUrl) {
				uni.showModal({
					content,
					title: desc,
					showCancel: isForce != 1,
					cancelText: '稍后进行',
					confirmText: '立即更新',
					success: ({ confirm }) => {
						if (confirm) {
							if (systemInfo().plat == 'android') {
								uni.showLoading({
									mask: true,
									title: '正在下载更新...',
								});
								toUpdate(updateUrl);
							} else {
								navigateTo(updateUrl);
							}
						}
					},
				});
			}
		}
	});
	// #endif
};

/** 下载更新 */
const toUpdate = (updateUrl: string) => {
	uni.downloadFile({
		url: updateUrl,
		success(res) {
			if (res.statusCode === 200) {
				uni.hideLoading();
				uni.showToast({
					title: '下载完成',
					icon: 'success',
				});
				plus.runtime.install(res.tempFilePath);
			} else {
				uni.hideLoading();
				uni.showToast({
					icon: 'error',
					title: '下载失败',
				});
			}
		},
		fail(res) {
			uni.hideLoading();
			uni.showToast({
				icon: 'error',
				title: '下载失败',
			});
		},
	});
};

/** 登录 */
export const toLogin = (type = 'push') => {
	let { path } = getRoute();
	if (!noLogin.includes(path)) {
		uni.showModal({
			title: '提示',
			content: '请先登录',
			showCancel: true,
			success: ({ confirm, cancel }) => {
				if (confirm) {
					navigateTo('/pages/mycenter/login', type);
				}
			},
		});
	}
};

/** 解析链接码 */
export const parseCode = (code: string) => {
	if (urlReg.test(code)) {
		const { query } = parseUrl(code);
		return query?.code;
	}
	return code;
};
