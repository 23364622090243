/* #ifdef H5 */
import _uni from './uni.webview';
/* #endif */
import qs from 'qs';
import { getPlatform } from './methods';
import type { route } from '@config/types';
import systemInfo from '@/stores/systemInfo';
import { tabbar, native, needLogin } from '@config/routes';

export const urlReg = /^(http(s)?:\/\/)[^\/]+/;

/** 分解url */
export const parseUrl = (url: string): route => {
	url.indexOf('://') != -1 && (url = url.replace(urlReg, ''));
	let arr = url.split('?');
	return {
		fullPath: url,
		path: arr[0],
		query: qs.parse(arr[1]),
	};
};

/** 获取当前路由 */
export const getRoute = (): route => {
	let route: route = {
		path: '',
		fullPath: '',
		query: {},
	};

	let pages = getCurrentPages();
	if (pages.length > 0) {
		let page: any = pages[pages.length - 1],
			fullPath = page.$page.fullPath;
		route = parseUrl(fullPath);
	}

	return route;
};

/** 返回上页 */
export const navigateBack = (num: number = -1) => {
	let delta = Math.abs(num),
		pages = getCurrentPages();

	if (pages.length > delta) {
		uni.navigateBack({ delta });
	} else {
		navigateTo('/pages/home');
	}
};

const navigate = (param: string | route, type: string = 'push') => {
	let route: any,
		platfrom = getPlatform(),
		plat = systemInfo().plat;

	if (typeof param == 'object') {
		let { path, query } = param;
		type = param.type || type;
		param.fullPath = path + qs.stringify(query, { addQueryPrefix: true });
		route = param;
	} else route = parseUrl(param);

	let { path, fullPath: url, query } = route;
	if (path == '') return;

	url.indexOf('/') != 0 && (url = '/' + url);

	let inTabbar = tabbar.includes(path),
		inNative = native.includes(path),
		inNeedLogin = needLogin.includes(path);

	if (inNeedLogin && !systemInfo().token) {
		uni.showModal({
			title: '提示',
			content: '请先登录',
			showCancel: true,
			success: ({ confirm, cancel }) => {
				confirm && navigateTo('/pages/mycenter/login');
			},
		});
		return;
	}

	const toUrl = () => {
		type == 'replace' ? uni.redirectTo({ url }) : uni.navigateTo({ url });
	};

	if (platfrom == 'h5' && inNative) {
		switch (plat) {
			case 'ios':
			case 'android':
				_uni.postMessage({
					data: {
						type: 1,
						params: { url },
					},
				});
				break;
			default:
				if (inTabbar) {
					uni.setStorageSync(path, query);
					uni.switchTab({ url });
				} else toUrl();
				break;
		}
	} else if (inNative) {
		if (inTabbar) {
			uni.setStorageSync(path, query);
			uni.switchTab({ url });
		} else toUrl();
	} else if (platfrom != 'h5') {
		uni.navigateTo({ url: `/pages/webview?url=${encodeURIComponent(url)}` });
	} else {
		toUrl();
	}
};

/** 是否为项目内页面 */
const isLocal = (url: string) => {
	let web_url = systemInfo().api.WAP_ROOT;
	return url.indexOf(web_url) != -1 || url.indexOf('http') == -1;
};

/** 跳转封装 */
export const navigateTo = (param: string | route | number, type: string = 'push'): void => {
	if (!param) return;

	if (typeof param == 'number') {
		navigateBack(param);
	} else if (typeof param == 'string' && !isLocal(param)) {
		if (getPlatform() == 'h5') {
			location.href = param;
		} else {
			uni.navigateTo({ url: `/pages/webview?url=${encodeURIComponent(param)}` });
		}
	} else {
		navigate(param, type);
	}
};

export const delayBack = (msg = '', delta = -1, delay = 1000) => {
	msg && uni.showToast({ title: msg, icon: 'none' });
	setTimeout(() => navigateBack(delta), delay);
};
